/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { NumericFormat } from 'react-number-format'
import type { PotentialClient, Contact } from 'lib/types'
import {
  getDenyNote,
  getPotentialClient,
  getOriginalPotentialClient,
} from 'lib/potential_client/selectors'
import {
  approveStep,
  createAsset,
  createContactRole,
  createDocument,
  createIncome,
  createDistribution,
  deleteDocument,
  denyStep,
  fetchPotentialClient,
  savePotentialClient,
  setEIN,
  removeAsset,
  removeContactRole,
  removeDistribution,
  removeIncome,
  setDenyNote,
  toggleReceivesStatements,
  updateAsset,
  updateContactRoleRelationship,
  updateDistribution,
  updateIncome,
  updateTrustAttr,
} from 'lib/potential_client/actions'
import { GRANTOR_RELATIONSHIPS, GSNT_GRANTOR_RELATIONSHIPS } from 'lib/constants'
import { ContactFormRef, ContactBasicFormRef } from 'components/References'
import { Table, Row, Cell } from 'components/Table'
import RichNotes from 'components/RichNotes'
import { setModal, unsetModal } from 'lib/modal/actions'
import { Loader } from 'components/Notifications'
import { ContactSelectModal } from 'components/ContactSelectModal'
import { Link } from 'react-router-dom'
import FileFolders from 'components/FileFolders'
import { ButtonAdd } from 'ui/components/Button'
import formatters from 'lib/formatters'
import styles from './styles'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  accountNumber: String,
  amount: Number,
  assets: [Object],
  ables: [Object],
  attorneys: [Object],
  co_grantor: Contact,
  contact: Contact,
  contacts: [Contact],
  consults: [Object],
  denyNote: String,
  description: String,
  disability_type: String,
  dispatch: Function,
  distributions: [Object],
  ein: String,
  folders: [Object],
  grantor: Contact,
  grantor_relationship: String,
  hasChanged: Boolean,
  header: String,
  history: [Object],
  id: Number,
  income: [Object],
  investment_advisors: [Contact],
  isHistorical: Boolean,
  accountNumber: Boolean,
  key: Number,
  name: String,
  nextStep: String,
  newNote: String,
  notes: [Object],
  onAddAbleClick: Function,
  onAddAttorneyClick: Function,
  onAddClick: Function,
  onAddCoGrantorClick: Function,
  onAddConsultClick: Function,
  onAddFamilyClick: Function,
  onAddGrantorClick: Function,
  onAddInvestmentAdvisorClick: Function,
  onAddProtectorClick: Function,
  onAddRepresentativeClick: Function,
  onAddResidualBeneficiaryClick: Function,
  onApprove: Function,
  onAttrChange: Function,
  onChange: Function,
  onBoolFieldToggle: Function,
  onDeleteClick: Function,
  onDescriptionUpdate: Function,
  onDeny: Function,
  onDisabilityChange: Function,
  onEINChange: Function,
  onNoteChange: Function,
  onRelationshipChange: Function,
  onRemoveRole: Function,
  onRemoveClick: Function,
  onStatusChange: Function,
  onSubmit: Function,
  onRequiredToggle: Function,
  otherFamily: [],
  onToggleReceivesStatements: Function,
  onUploadClick: Function,
  params: Object,
  potential_client: PotentialClient,
  product: String,
  protector: Object,
  relationship: String,
  remaindermen: [Contact],
  remaindermen_notes: String,
  representative: Contact,
  residual_beneficiaries: [Object],
  is_required: Boolean,
  showReceivesAccountStatements: Boolean,
  spouse: Object,
  status: String,
  source: String,
  trustAttrs: Object,
  trustTypeOptions: [Object],
  unspecified_family: Object,
  upload_status: String,
  userRoles: [String],
}

const emptyProps = {
  accountNumber: null,
  assets: [],
  contact: null,
  co_grantor: null,
  denyNote: null,
  disability_type: '',
  distributions: [],
  folders: [],
  ein: null,
  grantor: null,
  grantor_relationship: '',
  history: [],
  id: 0,
  income: [],
  nextStep: '',
  notes: [],
  remaindermen: [],
  remaindermenNotes: '',
  representative: null,
  status: '',
  userRoles: [],
}

const HeaderComponent = ({ status, isHistorical }: Props) => {
  const history = isHistorical ? ' History' : ''
  const title = `Potential Client Intake${history}`

  return (
    <div className={styles.headerSection}>
      <h1 className={styles.headerTitle}>{title}</h1>
      <div className={styles.headerStatus}>
        <h5>
          <a href="#review">{status}</a>
        </h5>
      </div>
    </div>
  )
}
const mapHeaderStateToProps = (state) => {
  const client = getPotentialClient(state)
  const status = client ? formatters.legiblePotentialClientStatus(client.status) : ''
  const isHistorical = client && client.account_number

  return { status, isHistorical }
}
const HeaderContainer = connect(mapHeaderStateToProps, null)(HeaderComponent)

const ReviewComponent = ({
  id,
  accountNumber,
  denyNote,
  hasChanged,
  history,
  nextStep,
  status,
  userRoles,
  onApprove,
  onDeny,
  onNoteChange,
}: Props) => {
  let approveEnabled = false
  let denyEnabled = false
  let showDenyButton = false

  if (status.startsWith('awaiting_attorney_review')) {
    showDenyButton = true

    if (userRoles.includes('attorney')) {
      approveEnabled = true
    }

    if (denyNote && denyNote.length > 0) {
      denyEnabled = true
    }
  }

  if (
    (status.startsWith('awaiting_staff_review') || status == 'approved') &&
    userRoles.includes('potential_client_reviewer')
  ) {
    approveEnabled = true
  }

  if (hasChanged) {
    approveEnabled = false
    denyEnabled = false
  }

  const approveButtonText = status == 'approved' ? 'Create Account' : 'Approve'

  const historyRow = (item, idx) => (
    <Row key={`history${idx}`}>
      <Cell value={item.approved ? 'Yes' : 'No'} />
      <Cell value={formatters.legiblePotentialClientStatus(item.previous_status)} />
      <Cell value={item.first_name + ' ' + item.last_name} />
      <Cell value={formatters.eventTimestamp(item.date)} />
    </Row>
  )

  const saveWarning = () => {
    if (hasChanged && status != 'approved') {
      return <p>* please save changes first</p>
    }
  }

  const approvalDiv = () => (
    <div>
      <p>
        <b>Step following approval: </b>
        {formatters.legiblePotentialClientStatus(nextStep)}
      </p>
      <div className={styles.reviewNotes}>
        <textarea
          value={denyNote || ''}
          placeholder="Reason if denied (required)"
          className={showDenyButton && userRoles.includes('attorney') ? '' : styles.hidden}
          onChange={(event) => onNoteChange(event.target.value)}
        />
      </div>
      <button
        className={styles.approveButton}
        disabled={!approveEnabled}
        type="submit"
        onClick={() => onApprove(id)}
      >
        {approveButtonText}
      </button>
      <button
        className={styles.denyButton}
        hidden={!showDenyButton}
        disabled={!denyEnabled}
        type="submit"
        onClick={() => onDeny(id, denyNote)}
      >
        Deny
      </button>
      {saveWarning()}
    </div>
  )

  const accountDiv = () => (
    <Link to={`/accounts/${accountNumber}`} className={styles.editContact}>
      {`View account ${accountNumber}`}
    </Link>
  )

  const lastDiv = () => {
    if (accountNumber) {
      return accountDiv()
    } else {
      return approvalDiv()
    }
  }

  return (
    <section className={styles.section}>
      <div id="review" className={styles.anchorLink} />
      <h2 className={styles.sectionTitle}>Review</h2>
      <p>
        <b>Current step: </b>
        {formatters.legiblePotentialClientStatus(status)}
      </p>
      <div>
        <Table
          title="Approval History"
          headers={['Approved', 'Status', 'User', 'Date']}
          tableSize="small"
          tableType="read"
        >
          {history.map(historyRow)}
        </Table>
      </div>
      {lastDiv()}
    </section>
  )
}
const mapReviewStateToProps = (state) => {
  const client = getPotentialClient(state)
  const orgClient = getOriginalPotentialClient(state)
  if (!client) {
    return emptyProps
  }

  const id = client.id
  const accountNumber = client.account_number
  const status = client.status
  const history = client.status_history
  const nextStep = client.next_step
  const userRoles = localStorage.getItem('roles')
  const denyNote = getDenyNote(state)
  const hasChanged = !_.isEqual(client, orgClient)

  return {
    id,
    accountNumber,
    status,
    hasChanged,
    history,
    nextStep,
    userRoles,
    denyNote,
  }
}
const mapDispatchToReviewProps = (dispatch: Props) => {
  return {
    onApprove: (id) => {
      dispatch(approveStep(id))
    },
    onDeny: (id, note) => {
      dispatch(denyStep(id, note))
    },
    onNoteChange: (val) => {
      dispatch(setDenyNote(val))
    },
  }
}
const ReviewContainer = connect(mapReviewStateToProps, mapDispatchToReviewProps)(ReviewComponent)

const TrustRadioNotesComponent = (
  title,
  boolAttr,
  boolValue,
  notesAttr,
  notesValue,
  notesDisplayed,
  onChange,
) => (
  <div className={styles.trustRadioNotes}>
    <div className={styles.radioButtonGroup}>
      <label className={styles.label}>{title}</label>
      <div className={styles.buttons}>
        <input type="radio" value="yes" checked={boolValue} onChange={() => onChange(boolAttr)} />
        <label htmlFor="yes">Yes</label>
        <input type="radio" value="no" checked={!boolValue} onChange={() => onChange(boolAttr)} />
        <label htmlFor="no">No</label>
      </div>
    </div>
    <textarea
      value={notesValue || ''}
      placeholder="Reason (required)"
      className={notesDisplayed ? '' : styles.hidden}
      onChange={(event) => onChange(notesAttr, event.target.value)}
    />
  </div>
)

const TrustComponent = ({ trustAttrs, trustTypeOptions, onAttrChange }: Props) => {
  const trustNameComponent = (
    <div className={styles.textInputGroup}>
      <label className={styles.label}>Trust Name</label>
      <input
        type="text"
        name="trust_name"
        value={trustAttrs.trust_name || ''}
        onChange={(event) => onAttrChange('trust_name', event.target.value)}
      />
    </div>
  )

  return (
    <section className={styles.section}>
      <div id="trust_info" className={styles.anchorLink} />
      <h2 className={styles.sectionTitle}>Trust Information</h2>

      <div className={styles.selectGroup}>
        <label className={styles.selectLabel} htmlFor="product_select">
          Product
        </label>
        <select
          className={styles.select}
          id="product_select"
          name="product"
          value={trustAttrs.product || ''}
          onChange={(event) => onAttrChange('product', event.target.value)}
        >
          <option value="" disabled>
            Please choose...
          </option>
          <option value="gta">GTA</option>
          <option value="gsnt_new">GSNT New</option>
        </select>
      </div>

      {trustAttrs.product != 'gsnt_old' && (
        <div className={styles.selectGroup}>
          <label className={styles.selectLabel} htmlFor="trust_type">
            Trust Type
          </label>
          <select
            className={styles.select}
            id="trust_type_select"
            name="trust_type"
            value={trustAttrs.trust_type || ''}
            onChange={(event) => onAttrChange('trust_type', event.target.value)}
          >
            {trustTypeOptions}
          </select>
        </div>
      )}

      {trustAttrs.product == 'gta' && trustNameComponent}

      <div className={styles.textInputGroup}>
        <label className={styles.label}>Initial trust funding deposit</label>
        <NumericFormat
          thousandSeparator={','}
          className={styles.currencyInput}
          prefix={'$'}
          value={trustAttrs.deposit_amount}
          onChange={(event) => {
            onAttrChange('deposit_amount', event.target.value)
          }}
        />
      </div>

      {TrustRadioNotesComponent(
        'Can we serve as trustee?',
        'can_serve_as_trustee',
        trustAttrs.can_serve_as_trustee,
        'needed_for_trustee',
        trustAttrs.needed_for_trustee,
        !trustAttrs.can_serve_as_trustee,
        onAttrChange,
      )}

      {TrustRadioNotesComponent(
        'Is the current trustee resigning?',
        'is_current_trustee_resigning',
        trustAttrs.is_current_trustee_resigning,
        'trustee_resign_reason',
        trustAttrs.trustee_resign_reason,
        trustAttrs.is_current_trustee_resigning,
        onAttrChange,
      )}
    </section>
  )
}
const mapTrustStateToProps = (state) => {
  const client = getPotentialClient(state)

  const emptyAttrs = {
    can_serve_as_trustee: true,
    needed_for_trustee: '',
    is_current_trustee_resigning: true,
    product: '',
    trustee_resign_reason: '',
    trust_name: '',
    trust_type: '',
    deposit_amount: 0,
  }

  if (!client) {
    return { trustAttrs: emptyAttrs }
  }

  const isGsnt = client.product && client.product.startsWith('gsnt')

  const trustTypes = isGsnt
    ? [{ val: 'gsnt_new_individual', display: 'Individual' }]
    : [
        { val: 'd4a', display: 'D4A' },
        { val: 'non_snt', display: 'Non-SNT' },
        { val: 'non_snt_3pty', display: 'Non-SNT Third-Party' },
      ]

  let trustTypeOptions = [
    <option key={'tt-0'} value="" disabled>
      Please choose...
    </option>,
  ]

  trustTypes.forEach(({ val, display }) => {
    trustTypeOptions.push(
      <option key={`tt-${val}`} value={val}>
        {display}
      </option>,
    )
  })

  const attrs = {
    product: client.product,
    trust_type: client.trust_type,
    trust_name: client.trust_name,
    can_serve_as_trustee: client.can_serve_as_trustee,
    needed_for_trustee: client.needed_for_trustee,
    is_current_trustee_resigning: client.is_current_trustee_resigning,
    trustee_resign_reason: client.trustee_resign_reason,
    deposit_amount: client.deposit_amount,
  }

  return { trustAttrs: attrs, trustTypeOptions: trustTypeOptions }
}
const mapDispatchToTrustProps = (dispatch: Props) => {
  return {
    onAttrChange: (attr, val) => {
      dispatch(updateTrustAttr(attr, val))
    },
  }
}
const TrustContainer = connect(mapTrustStateToProps, mapDispatchToTrustProps)(TrustComponent)

const DistributionsComponent = ({
  distributions = [],
  onAddClick,
  onRemoveClick,
  onRequiredToggle,
  onDescriptionUpdate,
}: Props) => {
  const distributionComponent = ({ id, is_required, description }: Props, idx) => (
    <div className={styles.distributionGroup} key={idx}>
      <div className={styles.distributionRemove}>
        <input
          className={styles.potentialClientRemoveButton}
          type="button"
          value="X"
          onClick={() => onRemoveClick(id)}
        />
      </div>
      <div className={styles.distributionChild}>
        <div className={styles.radioButtonGroupVertical}>
          <span>
            <input
              type="radio"
              id="required"
              value="yes"
              checked={is_required}
              onChange={() => onRequiredToggle(idx)}
            />
            <label htmlFor="required">Required</label>
          </span>
          <span>
            <input
              type="radio"
              id="allowable"
              value="no"
              checked={!is_required}
              onChange={() => onRequiredToggle(idx)}
            />
            <label htmlFor="allowable">Allowable</label>
          </span>
        </div>
        <div className={styles.description}>
          <textarea
            value={description || ''}
            onChange={(event) => onDescriptionUpdate(idx, event.target.value)}
          />
        </div>
      </div>
    </div>
  )

  return (
    <section className={styles.section}>
      <div id="distributions" className={styles.anchorLink} />
      <h2 className={styles.sectionTitle}>Distributions</h2>
      {distributions.map((d, idx) => distributionComponent(d, idx))}
      <ButtonAdd label="Add distribution" onClick={() => onAddClick()} />
    </section>
  )
}
const mapDistributionsStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  return { distributions: client.distributions }
}
const mapDispatchToDistributionProps = (dispatch: Props) => {
  return {
    onRequiredToggle: (idx) => {
      dispatch(updateDistribution(idx, 'is_required'))
    },
    onDescriptionUpdate: (idx, val) => {
      dispatch(updateDistribution(idx, 'description', val))
    },
    onAddClick: () => {
      dispatch(createDistribution())
    },
    onRemoveClick: (id) => {
      dispatch(removeDistribution(id))
    },
  }
}
const DistributionsContainer = connect(
  mapDistributionsStateToProps,
  mapDispatchToDistributionProps,
)(DistributionsComponent)

const IncomeAssetComponent = (
  { id, source, amount }: Props,
  idx,
  onAttrChange,
  onRemoveClick,
  subtype = null,
) => (
  <div className={styles.incomeAssetGroup} key={idx}>
    <div className={styles.incomeAsset}>
      <label className={styles.label}>Source</label>
      <input
        className={styles.einInput}
        type="text"
        value={source}
        onChange={(event) => onAttrChange(idx, 'source', event.target.value, subtype)}
      />
    </div>
    <div className={styles.incomeAsset}>
      <label className={styles.label}>Amount</label>
      <NumericFormat
        thousandSeparator={true}
        className={styles.incomeAssetAmount}
        prefix={'$'}
        value={amount}
        onValueChange={(values) => {
          onAttrChange(idx, 'amount', values.value, subtype)
        }}
      />
      <input
        className={`${styles.potentialClientRemoveButton} ` + `${styles.incomeAssetRemoveButton}`}
        type="button"
        value="X"
        onClick={() => onRemoveClick(id)}
      />
    </div>
  </div>
)

// const SSIComponent = (
//   {
//     has_ssi,
//     has_ssi_food,
//     has_ssi_shelter,
//     ssi_amount,
//     onAttrChange
//   }: Props) => (
//   <section className={styles.section}>
//     <div id="income" className={styles.anchorLink} />
//     <h2 className={styles.sectionTitle}>Monthly SSI</h2>
//   </section>
// )
// const mapSSIStateToProps = (state) => {
//   const client = getPotentialClient(state)

//   if (!client) {
//     return emptyProps
//   }

//   return {
//     has_ssi: client.has_ssi,
//     has_ssi_food: client.has_ssi_food,
//     has_ssi_shelter: client.has_ssi_shelter,
//     ssi_amount: client.ssi_amount
//   }
// }
// const mapDispatchToSSIProps = (dispatch: Props) => {
//   return {
//     onAttrChange: (attr, val) => {
//       dispatch(updateSSI(attr, val))
//     },
//   }
// }
// const SSIContainer =
//   connect(mapSSIStateToProps, mapDispatchToSSIProps)(SSIComponent)

const IncomeComponent = ({ income, onAddClick, onAttrChange, onRemoveClick }: Props) => (
  <section className={styles.section}>
    <div id="income" className={styles.anchorLink} />
    <h2 className={styles.sectionTitle}>Monthly Income</h2>
    <p>
      <i>
        Note: If SSI, staff will need to add this via `Edit Account` after the account is created.
      </i>
    </p>
    {income.map((i, idx) => IncomeAssetComponent(i, idx, onAttrChange, onRemoveClick))}
    <ButtonAdd label="Add monthly income" onClick={() => onAddClick()} />
  </section>
)
const mapIncomeStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  return {
    income: client.income,
  }
}
const mapDispatchToIncomeProps = (dispatch: Props) => {
  return {
    onAttrChange: (idx, attr, val) => {
      dispatch(updateIncome(idx, attr, val))
    },
    onAddClick: () => {
      dispatch(createIncome())
    },
    onRemoveClick: (id) => {
      dispatch(removeIncome(id))
    },
  }
}
const IncomeContainer = connect(mapIncomeStateToProps, mapDispatchToIncomeProps)(IncomeComponent)

const AssetsComponent = ({ assets, onAddClick, onAttrChange, onRemoveClick }: Props) => {
  const subtypeComponents = (subtype) => {
    const subAssets = assets?.filter((a) => a.subtype == subtype)

    return subAssets?.map((i, idx) =>
      IncomeAssetComponent(i, idx, onAttrChange, onRemoveClick, subtype),
    )
  }
  return (
    <section id={styles.assetGroup} className={styles.section}>
      <div id="assets" className={styles.anchorLink} />
      <h2 className={styles.sectionTitle}>Assets</h2>
      <h5 className={styles.assetSubtype}>Cash</h5>
      {subtypeComponents('cash')}
      <ButtonAdd label="Add cash" onClick={() => onAddClick('cash')} />
      <h5 className={styles.assetSubtype}>Investments</h5>
      {subtypeComponents('investment')}
      <ButtonAdd label="Add investment" onClick={() => onAddClick('investment')} />
      <h5 className={styles.assetSubtype}>Real Estate</h5>
      {subtypeComponents('real_estate')}
      <ButtonAdd label="Add real estate" onClick={() => onAddClick('real_estate')} />
      <h5 className={styles.assetSubtype}>Other</h5>
      {subtypeComponents('other')}
      <ButtonAdd label="Add other asset" onClick={() => onAddClick('other')} />
    </section>
  )
}
const mapAssetsStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  return {
    assets: client.assets,
  }
}
const mapDispatchToAssetsProps = (dispatch: Props) => {
  return {
    onAttrChange: (idx, attr, val, subtype) => {
      dispatch(updateAsset(idx, attr, val, subtype))
    },
    onAddClick: (subtype) => {
      dispatch(createAsset(subtype))
    },
    onRemoveClick: (id) => {
      dispatch(removeAsset(id))
    },
  }
}
const AssetsContainer = connect(mapAssetsStateToProps, mapDispatchToAssetsProps)(AssetsComponent)

const findContactsByRoles = (contacts, roles) => contacts.filter((e) => roles.includes(e.type))

const RemaindermenComponent = ({
  remaindermen,
  remaindermenNotes,
  hideRemaindermen,
  onAddRemaindermenClick,
  onRemoveRemaindermenClick,
  onRemaindermenNotesChange,
}: Props) => {
  const component = (
    <div>
      <section className={styles.section}>
        <div id="remaindermen" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Remaindermen</h2>
        <textarea
          className={styles.notesTextArea}
          value={remaindermenNotes || ''}
          onChange={(event) => onRemaindermenNotesChange(event.target.value)}
          placeholder="Remaindermen notes"
        />
        {remaindermen.map((c, idx) =>
          ContactComponent(c, [], null, onRemoveRemaindermenClick, idx),
        )}
        <ButtonAdd label="Add remaindermen" onClick={() => onAddRemaindermenClick()} />
      </section>
    </div>
  )

  return !hideRemaindermen && component
}
const mapRemaindermenStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  let hideRemaindermen = true

  if (client.product == 'gsnt_new') {
    hideRemaindermen = false
  }

  const remaindermen = findContactsByRoles(client.contact_roles, ['remaindermen'])

  return {
    remaindermen: remaindermen,
    remaindermenNotes: client.remaindermen_notes,
    hideRemaindermen: hideRemaindermen,
  }
}
const mapDispatchToRemaindermenProps = (dispatch: Props) => {
  return {
    onRemaindermenNotesChange: (val) => {
      dispatch(updateTrustAttr('remaindermen_notes', val))
    },
    onAddRemaindermenClick: () => {
      createOnAddClickHandler(dispatch, 'remaindermen')
    },
    onRemoveRemaindermenClick: (id) => {
      dispatch(removeContactRole(id))
    },
  }
}
const RemaindermenContainer = connect(
  mapRemaindermenStateToProps,
  mapDispatchToRemaindermenProps,
)(RemaindermenComponent)

const BeneficiaryComponent = ({ contact, disability_type, onDisabilityChange }: Props) => (
  <div>
    <section className={styles.section}>
      <div id="beneficiary" className={styles.anchorLink} />
      <h2 className={styles.sectionTitle}>Beneficiary</h2>
      <Link to={`/contacts/${contact && contact.id}/edit`} className={styles.editContact}>
        Edit Contact
      </Link>
      <ContactFormRef contact={contact} />
    </section>
    <section className={styles.section}>
      <div id="disability_type" className={styles.anchorLink} />
      <h2 className={styles.sectionTitle}>Medical Information</h2>
      <textarea
        className={styles.notesTextArea}
        value={disability_type || ''}
        onChange={(event) => onDisabilityChange(event.target.value)}
      />
    </section>
  </div>
)
const mapBeneficiaryStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  return {
    contact: client.beneficiary,
    disability_type: client.disability_type,
  }
}
const mapDispatchToBeneficiaryProps = (dispatch: Props) => {
  return {
    onDisabilityChange: (val) => {
      dispatch(updateTrustAttr('disability_type', val))
    },
  }
}
const BeneficiaryContainer = connect(
  mapBeneficiaryStateToProps,
  mapDispatchToBeneficiaryProps,
)(BeneficiaryComponent)

const EINComponent = ({ ein, hideEin, onEINChange }: Props) => {
  const component = (
    <section className={styles.section}>
      <div className={styles.formItemMono}>
        <div id="ein" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>EIN</h2>
        <NumericFormat
          format="##-#######"
          className={styles.einInput}
          mask="_"
          value={ein}
          onChange={(e) => onEINChange(ein, e.target.value)}
        />
      </div>
    </section>
  )

  return !hideEin && component
}
const mapEINStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  let hideEin = false

  if (client.product) {
    if (client.product == 'gsnt_new' && client.trust_type == 'pooled') {
      hideEin = true
    } else if (client.product == 'gsnt_old') {
      hideEin = true
    }
  }

  return {
    ein: client.ein,
    hideEin: hideEin,
  }
}
const mapDispatchToEINProps = (dispatch: Props) => {
  return {
    onEINChange: (oldEin, newVal) => {
      const newEin = newVal.replace('-', '').replace('_', '')

      if (newEin.length == 9 && newEin != oldEin) {
        dispatch(setEIN(newEin))
      }
    },
  }
}
const EINContainer = connect(mapEINStateToProps, mapDispatchToEINProps)(EINComponent)

const GrantorComponent = ({
  grantor_relationship,
  grantor,
  co_grantor,
  product,
  representative,
  onAttrChange,
  onAddGrantorClick,
  onAddCoGrantorClick,
  onAddRepresentativeClick,
  onRemoveRole,
}: Props) => {
  let relationship_options = [
    <option key={'rel-0'} value="" disabled>
      Please choose...
    </option>,
  ]

  const grantorOrFundedBy = formatters.grantorOrFundedBy(product)

  const relationships = product == 'gsnt_new' ? GSNT_GRANTOR_RELATIONSHIPS : GRANTOR_RELATIONSHIPS

  Object.keys(relationships).forEach((key) => {
    relationship_options.push(<option value={key}>{relationships[key]}</option>)
  })

  const grantorDiv = () => {
    if (['self', 'selfRep'].includes(grantor_relationship)) {
      return <div></div>
    } else {
      if (grantor) {
        return ContactComponent(grantor, [], null, onRemoveRole, 0)
      } else {
        return (
          <div className={styles.grantorButtons}>
            <Link
              onClick={(event) => {
                event.preventDefault()
                onAddGrantorClick()
              }}
              className={styles.editContact}
              target="_blank"
            >
              {`Choose ${grantorOrFundedBy}`}
            </Link>
          </div>
        )
      }
    }
  }

  const coGrantorDiv = () => {
    if (grantor_relationship != 'coGuardian') {
      return <div></div>
    } else {
      if (co_grantor) {
        return ContactComponent(co_grantor, [], null, onRemoveRole, 1)
      } else {
        return (
          <div key="cograntor" className={styles.grantorButtons}>
            <Link
              onClick={() => onAddCoGrantorClick()}
              className={styles.editContact}
              target="_blank"
            >
              Choose Co-Grantor
            </Link>
          </div>
        )
      }
    }
  }

  const repDiv = () => {
    if (grantor_relationship != 'selfRep') {
      return <div></div>
    } else {
      if (representative) {
        return ContactComponent(representative, [], null, onRemoveRole, 2)
      } else {
        return (
          <div className={styles.grantorButtons}>
            <Link
              onClick={() => onAddRepresentativeClick()}
              className={styles.editContact}
              target="_blank"
            >
              Choose Representative
            </Link>
          </div>
        )
      }
    }
  }

  return (
    <div>
      <section className={styles.section}>
        <div id="grantor" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>{`${grantorOrFundedBy} Details`}</h2>

        <label className={styles.selectLabel} htmlFor="status_select">
          {`${grantorOrFundedBy} Relationship`}
        </label>
        <select
          className={styles.select}
          id="grantor_relationship_select"
          name="grantor_relationship"
          value={grantor_relationship || ''}
          onChange={(event) => onAttrChange('grantor_relationship', event.target.value)}
        >
          {relationship_options}
        </select>
        {grantorDiv()}
        {coGrantorDiv()}
        {repDiv()}
      </section>
    </div>
  )
}
const mapGrantorStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  const grantor = client.contact_roles.find((e) => e.type == 'grantor')
  const co_grantor = client.contact_roles.find((e) => e.type == 'co_grantor')
  const representative = client.contact_roles.find((e) => e.type == 'representative')

  return {
    product: client.product,
    grantor_relationship: client.grantor_relationship,
    grantor,
    co_grantor,
    representative,
  }
}
const mapDispatchToGrantorProps = (dispatch: Props) => {
  return {
    onAttrChange: (attr, val) => {
      dispatch(updateTrustAttr(attr, val))
    },
    onAddGrantorClick: () => {
      createOnAddClickHandler(dispatch, 'grantor')
    },
    onAddCoGrantorClick: () => {
      createOnAddClickHandler(dispatch, 'co_grantor')
    },
    onAddRepresentativeClick: () => {
      createOnAddClickHandler(dispatch, 'representative')
    },
    onRemoveRole: (id) => {
      dispatch(removeContactRole(id))
    },
  }
}
const GrantorContainer = connect(
  mapGrantorStateToProps,
  mapDispatchToGrantorProps,
)(GrantorComponent)

const createOnAddClickHandler = (dispatch, relationship_type) => {
  const handler = (contact: Object) => () => {
    dispatch(unsetModal())
    dispatch(createContactRole(relationship_type, contact))
  }

  return dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
}

const ContactComponent = (
  contact_role = null,
  relationshipOptions = [],
  onRelationshipChange = null,
  onRemoveRole = null,
  key = 0,
  onToggleReceivesStatements = null,
) => {
  if (contact_role) {
    const contactRemoveButton = (
      <div className={styles.removeButton} onClick={() => onRemoveRole(contact_role.id)} />
    )

    const accountStatementsDiv = () => {
      if (onToggleReceivesStatements) {
        return (
          <div className={styles.radioButtonGroup}>
            <div className={styles.buttons}>
              <input
                type="checkbox"
                id="receives_account_statements"
                checked={contact_role.receives_account_statements}
                onChange={() => onToggleReceivesStatements(contact_role)}
              />
              <label htmlFor="receives_account_statements">Receives account statements</label>
            </div>
          </div>
        )
      } else {
        return <div></div>
      }
    }

    return (
      <div key={key} className={styles.contactDiv}>
        <ContactBasicFormRef
          contact={contact_role.contact}
          relationship={relationshipOptions.length > 0 ? contact_role.type : null}
          relationshipId={contact_role.id}
          relationshipOptions={relationshipOptions}
          remove={contactRemoveButton}
          onRelationshipChange={onRelationshipChange}
        />
        {accountStatementsDiv()}
      </div>
    )
  } else {
    return <div key={key}></div>
  }
}

const ContactsComponent = ({
  ables = [],
  consults = [],
  investment_advisors = [],
  unspecified_family = [],
  spouse = null,
  otherFamily = [],
  attorneys = [],
  protector = null,
  residual_beneficiaries = [],
  onAddAbleClick,
  onAddConsultClick,
  onAddFamilyClick,
  onAddAttorneyClick,
  onAddProtectorClick,
  onRelationshipChange,
  onAddResidualBeneficiaryClick,
  onAddInvestmentAdvisorClick,
  onRemoveRole,
  onToggleReceivesStatements,
}: Props) => {
  const familyRelationshipOptions = [
    ['unspecified', 'Please Choose...'],
    ['father', 'Father'],
    ['mother', 'Mother'],
    ['husband', 'Husband'],
    ['wife', 'Wife'],
    ['daughter', 'Daughter'],
    ['son', 'Son'],
    ['brother', 'Brother'],
    ['sister', 'Sister'],
    ['other_family', 'Other'],
  ]

  const attorneyRelationshipOptions = [
    ['unspecified', 'Please Choose...'],
    ['beneficiary_attorney', 'Beneficiary Attorney'],
    ['grantor_attorney', 'Grantor Attorney'],
    ['referring_attorney', 'Referring Attorney'],
  ]

  return (
    <div>
      <section className={styles.section}>
        <div id="family" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Family</h2>
        {ContactComponent(spouse, familyRelationshipOptions, onRelationshipChange, onRemoveRole)}
        {otherFamily.map((cr, idx) =>
          ContactComponent(cr, familyRelationshipOptions, onRelationshipChange, onRemoveRole, idx),
        )}
        {unspecified_family.map((cr, idx) =>
          ContactComponent(cr, familyRelationshipOptions, onRelationshipChange, onRemoveRole, idx),
        )}
        <ButtonAdd label="Add family member" onClick={() => onAddFamilyClick()} />
      </section>
      <section className={styles.section}>
        <div id="attorneys" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Attorneys</h2>
        {attorneys.map((cr, idx) =>
          ContactComponent(
            cr,
            attorneyRelationshipOptions,
            onRelationshipChange,
            onRemoveRole,
            idx,
          ),
        )}
        <ButtonAdd label="Add attorney" onClick={() => onAddAttorneyClick()} />
      </section>
      <section className={styles.section}>
        <div id="protector" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Trust Protector</h2>
        {ContactComponent(protector, [], null, onRemoveRole)}
        {protector ? (
          <div></div>
        ) : (
          <ButtonAdd label="Add protector" onClick={() => onAddProtectorClick()} />
        )}
      </section>
      <section className={styles.section}>
        <div id="residual_beneficiaries" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Residual Beneficiaries</h2>
        {residual_beneficiaries.map((r, idx) =>
          ContactComponent(r, [], null, onRemoveRole, idx, onToggleReceivesStatements),
        )}
        <ButtonAdd
          label="Add residual beneficiary"
          onClick={() => onAddResidualBeneficiaryClick()}
        />
      </section>
      <section className={styles.section}>
        <div id="ables_consults" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Ables</h2>
        {ables.map((a, idx) => ContactComponent(a, [], null, onRemoveRole, idx))}
        <ButtonAdd label="Add able" onClick={() => onAddAbleClick()} />
      </section>
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Consults</h2>
        {consults.map((c, idx) => ContactComponent(c, [], null, onRemoveRole, idx))}
        <ButtonAdd label="Add consult" onClick={() => onAddConsultClick()} />
      </section>
      <section className={styles.section}>
        <div id="investment_advisors" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Investment Advisors</h2>
        {investment_advisors.map((cr, idx) => ContactComponent(cr, [], null, onRemoveRole, idx))}
        <ButtonAdd label="Add investment advisor" onClick={() => onAddInvestmentAdvisorClick()} />
      </section>
    </div>
  )
}
const mapContactsStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  const spouse = client.contact_roles.find((e) => ['husband', 'wife'].includes(e.type))
  const unspecified_family = findContactsByRoles(client.contact_roles, ['unspecified_family'])
  const otherFamily = findContactsByRoles(client.contact_roles, [
    'other_family',
    'daughter',
    'son',
    'father',
    'mother',
    'brother',
    'sister',
  ])
  const ables = findContactsByRoles(client.contact_roles, ['able'])
  const consults = findContactsByRoles(client.contact_roles, ['consult'])
  const attorneys = findContactsByRoles(client.contact_roles, [
    'unspecified_attorney',
    'beneficiary_attorney',
    'grantor_attorney',
    'referring_attorney',
  ])
  const protector = client.contact_roles.find((e) => e.type == 'protector')
  const bens = findContactsByRoles(client.contact_roles, ['residual_beneficiary'])
  const investment_advisors = findContactsByRoles(client.contact_roles, ['investment_advisor'])

  return {
    unspecified_family,
    spouse,
    otherFamily,
    ables,
    consults,
    attorneys,
    protector,
    investment_advisors,
    residual_beneficiaries: bens,
  }
}
const mapDispatchToContactProps = (dispatch: Props) => {
  return {
    onAddFamilyClick: () => {
      createOnAddClickHandler(dispatch, 'unspecified_family')
    },
    onAddAttorneyClick: () => {
      createOnAddClickHandler(dispatch, 'unspecified_attorney')
    },
    onAddAbleClick: () => {
      createOnAddClickHandler(dispatch, 'able')
    },
    onAddConsultClick: () => {
      createOnAddClickHandler(dispatch, 'consult')
    },
    onAddProtectorClick: () => {
      createOnAddClickHandler(dispatch, 'protector')
    },
    onAddResidualBeneficiaryClick: () => {
      createOnAddClickHandler(dispatch, 'residual_beneficiary')
    },
    onAddInvestmentAdvisorClick: () => {
      createOnAddClickHandler(dispatch, 'investment_advisor')
    },
    onRelationshipChange: (role_id, contact_id, relationship_type) => {
      dispatch(updateContactRoleRelationship(role_id, contact_id, relationship_type))
    },
    onRemoveRole: (id) => {
      dispatch(removeContactRole(id))
    },
    onToggleReceivesStatements: (role) => {
      dispatch(toggleReceivesStatements(role))
    },
  }
}
const ContactsComponentContainer = connect(
  mapContactsStateToProps,
  mapDispatchToContactProps,
)(ContactsComponent)

const NotesComponent = ({ id, notes }: Props) => {
  return (
    <section className={styles.section}>
      <div className={styles.formItemMono}>
        <div id="notes" className={styles.anchorLink} />
        <h2 className={styles.sectionTitle}>Intake Notes</h2>
        <RichNotes parentEntity="potential_clients" parentId={id} notes={notes} />
      </div>
    </section>
  )
}
const mapNotesStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  return {
    id: client.id,
    notes: client.notes,
  }
}
const NotesContainer = connect(mapNotesStateToProps)(NotesComponent)

const DocumentsComponent = ({
  id,
  accountNumber,
  folders,
  onDeleteClick,
  onUploadClick,
}: Props) => {
  const foldersDiv = (
    <div className={styles.fileFoldersDiv}>
      <FileFolders
        folders={folders}
        parentEntityId={id}
        onDeleteClick={onDeleteClick}
        onUploadClick={onUploadClick}
      >
        <span className="spinner" />
      </FileFolders>
    </div>
  )

  const openDiv = () => {
    const link = (
      <Link to={`/accounts/${accountNumber}`} target="_blank">
        account page
      </Link>
    )

    return (
      <div>
        <p>Please see {link} for current documents.</p>
      </div>
    )
  }

  return (
    <section className={styles.section}>
      <div id="documents" className={styles.anchorLink} />
      <h2 className={styles.sectionTitle}>Documents</h2>
      {accountNumber ? openDiv() : foldersDiv}
    </section>
  )
}
const mapDocumentsStateToProps = (state) => {
  const client = getPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  const mergedFolders = FileFolders.MergeFoldersWithDefaults(client.folders)
  return {
    id: client.id,
    accountNumber: client.account_number,
    folders: mergedFolders,
  }
}
const mapDispatchToDocumentsProps = (dispatch: Props) => {
  const buildUploadParams = async (folder, fileName, file) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })

    const data = await toBase64(file)
    const params = {
      content_type: file.type,
      name: fileName,
      folder: folder,
      data: data.split(',')[1],
    }

    return params
  }

  return {
    onUploadClick: (id, folder, fileName, file) => {
      buildUploadParams(folder, fileName, file).then((params) => {
        dispatch(createDocument(id, params))
      })
    },
    onDeleteClick: (folder, id) => {
      dispatch(deleteDocument(folder, id))
    },
  }
}
const DocumentsContainer = connect(
  mapDocumentsStateToProps,
  mapDispatchToDocumentsProps,
)(DocumentsComponent)

const UploadReviewComponent = ({ upload_status }: Props) => {
  const loading = upload_status === 'uploading'

  return <Loader non_redux_loading={loading} />
}
const mapUploadStatusStateToProps = (state) => {
  const client = getPotentialClient(state)
  const upload_status = client ? client.upload_status : null
  return { upload_status }
}
const UploadReviewContainer = connect(mapUploadStatusStateToProps, null)(UploadReviewComponent)

const SaveButtonComponent = ({ potential_client, hasChanged, onSubmit }: Props) => {
  const button = (
    <div>
      <button
        className={styles.saveButton}
        hidden={!hasChanged}
        type="submit"
        onClick={() => onSubmit(potential_client)}
      >
        Save Changes
      </button>
    </div>
  )

  const buttonOrWarning = () => {
    if (potential_client && potential_client.account_number) {
      return <p>Changes cannot be made after account is created</p>
    } else {
      return <div>{button}</div>
    }
  }

  return <div>{buttonOrWarning()}</div>
}
const mapStateToSaveProps = (state) => {
  const client = getPotentialClient(state)
  const orgClient = getOriginalPotentialClient(state)

  if (!client) {
    return emptyProps
  }

  return {
    potential_client: client,
    hasChanged: !_.isEqual(client, orgClient),
  }
}
const mapDispatchToSaveProps = (dispatch: Props) => {
  return {
    onSubmit: (params) => {
      dispatch(savePotentialClient(params))
    },
  }
}
const SaveButtonContainer = connect(
  mapStateToSaveProps,
  mapDispatchToSaveProps,
)(SaveButtonComponent)

const Edit = () => (
  <section className={styles.contentContainer}>
    <div className={styles.row}>
      <section className={styles.formWrapper}>
        <HeaderContainer />
        <BeneficiaryContainer />
        <EINContainer />
        <TrustContainer />
        <GrantorContainer />
        <ContactsComponentContainer />
        <DistributionsContainer />
        <IncomeContainer />
        <RemaindermenContainer />
        <AssetsContainer />
        <DocumentsContainer />
        <NotesContainer />
        <ReviewContainer />
        <div className={styles.navigationSave}>
          <SaveButtonContainer />
        </div>
        <UploadReviewContainer />
      </section>
      <section className={styles.menuWrapper}>
        <div className={styles.navigationMenuWrapper}>
          <h5 className="sectionTitle">Navigation</h5>
          <div className={styles.scrollArea}>
            <a href="#beneficiary" className={styles.menuLink}>
              Beneficiary Info
            </a>
            <a href="#trust_info" className={styles.menuLink}>
              Trust Information
            </a>
            <a href="#grantor" className={styles.menuLink}>
              Grantor / Funded By Information
            </a>
            <a href="#family" className={styles.menuLink}>
              Family
            </a>
            <a href="#attorneys" className={styles.menuLink}>
              Attorneys
            </a>
            <a href="#protector" className={styles.menuLink}>
              Trust Protector
            </a>
            <a href="#residual_beneficiaries" className={styles.menuLink}>
              Residual Beneficiaries
            </a>
            <a href="#ables_consults" className={styles.menuLink}>
              Ables and Consults
            </a>
            <a href="#investment_advisors" className={styles.menuLink}>
              Investment Advisors
            </a>
            <a href="#distributions" className={styles.menuLink}>
              Distributions
            </a>
            <a href="#income" className={styles.menuLink}>
              Income and Assets
            </a>
            <a href="#remaindermen" className={styles.menuLink}>
              Remaindermen
            </a>
            <a href="#documents" className={styles.menuLink}>
              Documents
            </a>
            <a href="#notes" className={styles.menuLink}>
              Intake Notes
            </a>
            <a href="#review" className={styles.menuLink}>
              Review
            </a>
            <div className={styles.navigationSave}>
              <SaveButtonContainer />
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
)

class EditContainer extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  props: Props

  componentDidMount() {
    const { dispatch, location } = this.props
    const { id } = location.params
    dispatch(fetchPotentialClient(id))
  }

  render() {
    return <Edit />
  }
}

export default withRouter(connect()(EditContainer))
